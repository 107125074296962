* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: rgba(245, 245, 245, 0.479);
}

.navbar {
    overflow: hidden;
    background: tan;

    icon {
        float: right;
    }

    ul {
        // padding-top: 1.3%;
        padding-right: 15px;
        padding-top: 1.1%;
        position: relative;
        margin: auto;
        text-align: right;
        list-style: none;
        display: grid;
        grid-template-columns: auto auto;
        gap: 16px;
        float: right;

        li {
            align-items: right;
            
            a {
                font-size: 15px;
                padding: 7px;
                margin: 7px;
                text-decoration: none;
        
                &:hover svg {
                    color: white;
                }
            }
        }
    }
}

.nav-title {
    font-size: 35px;
    float: left;
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    padding-left: 20px;
    // padding-top: 15px;
}

.nav-links {
    text-align: center;
    font-weight: bold;
}

// Extra Small Devices
@media screen and (max-width: 576px) {
    body {
        // margin: 0;
    }

    .navbar {
        overflow: hidden;
        background: tan;

        ul {
            margin: auto;
            text-align: left;
            list-style: none;
            gap: 0px;
            display: flex;
        }
    }

    .nav-title {
        font-size: 20px;
        margin: auto;
        padding: auto;
        // width: 25%;
        padding-top: 20px;
        text-align: center;
    }

    .nav-links {
        font-size: 10px;
        text-align: right;
    }

}

// Tablet
@media screen and (min-width: 577px) and (max-width: 835px) {
    .nav-links {
        padding-bottom: 3%;
        
    }

    .nav-title {
        padding-top: 15px;
    }
}

@media screen and (max-width: 991.98px) {

}

// Laptop
@media screen and (max-width: 1199.98px) {
    .nav-links {
        padding-top: 12px;
    }

    .nav-title {
        padding-top: 15px;
    }
}

@media screen and (max-width: 1399.98px) {

}
.flex-container {
    display: flex;
    flex-wrap: nowrap;
}

.card-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    text-align: center;
    background-color: tan;
    padding: 50px;
    border-radius: 75px;
}

.card-title {
    font-size: 23px;
    justify-content: center;
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
}

.project-heading {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    text-align: center;
    font-size: 56px;
}

.project-subheading {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    text-align: center;
    font-size: 25px;
}

.project-images {
    transition: transform 0.3s ease-in-out;
    border-radius: 5%;
}

.project-images:hover {
    transform: scale(1.3);
    cursor: pointer;
}

.card-text {
    margin: auto;
    align-items: center;
    padding: 25px;
}

@media screen and (max-width: 576px) {
    .flex-container {
        display: flex;
        flex-wrap: nowrap;
    }

    .card-container {
        display: grid;
        grid-template-columns: auto;
        text-align: center;
        justify-content: center;
        padding: 30px;
    }

    .project-images {
        transition: transform 0.3s ease-in-out;
        margin: auto;
    }
    
    .project-images:hover {
        transform: scale(1.3);
        cursor: pointer;
    }

    .card-skills:hover {
        color: white;
    }
    
}

// Tablet
@media screen and (max-width: 835px) {
    .card-container {
        display: grid;
        grid-template-columns: auto;
        text-align: center;
        justify-content: center;
    }
}

// Laptop
@media screen and (max-width: 1280px) {
    
}

* {
    box-sizing: border-box;
}

body {
    // font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.container-home {
    position: absolute;
    overflow: hidden;
    padding: 0 15%;
}

.main-header {
    text-align: center;
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-size: 60px;
}

.header-container {
    // padding-top: 5%;
    // padding-bottom: 25%;
}

.flex-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.container-one, .container-two {
    display: grid;
    grid-template-columns: auto auto;
    // align-items: center;
    // padding: 10px;
}

.about-section, .skills-section, .facts-section, .project-section, .service-section {
    padding-bottom: 15px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    // padding: 50px;
    // margin: 20px;
}

.skill-list {
    display: grid;
    grid-template-columns: auto;
    list-style: none;
    border: white 2px solid;
    border-radius: 75px;
    background-color: tan;
    padding: 50px;
}

.facts-container {
    padding: 25px;
    border: white 2px solid;
    background-color: tan;
    border-radius: 75px;
}

.info-container, .service-container {
    padding: 25px;
    border: white 2px solid;
    background-color: lightgray;
    border-radius: 75px;
}

.about-me, .facts-heading, .project-heading, .skills-heading, .service-heading {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    text-align: center;
    font-size: 47px;
}

.service-subheading {
    font-size: 40px;
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
}

.info-text {
    text-align: left;
    font-size: 27px;
    padding: 5px;
}

.skills-text {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-size: 30px;
    padding: 15px;
}

.resume-link {
    font-size: 30px;
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    color: black;

    &:hover {
        color: blue;
    }
}

.image-container {
    padding-bottom: 5%;
    text-align: center;
    width: 100%;
}

.vacation-img {
    border-radius: 25px;
}

.email-link {
    color: black;

    &:hover {
        color: blue;
    }
}

ul {
    li {
        list-style: none;
        
            a {
                color: black;
            }
        }
    }

// Extra Small Devices
@media screen and (max-width: 576px) {
    body {
        margin: 0;
    }

    .container-one, .container-two {
        display: grid;
        grid-template-columns: auto;
        // padding: -50px;
    }

    .flex-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }

    .main-header {
        font-size: 55px;
        align-items: center;
    }

    .header-container {
        // padding-top: 10%;
        // padding-bottom: 5%;
    }

    .container-home {
        width: 95%;
        padding: 0 2%;
    }

    .about-section, .skills-section, .facts-section, .project-section {
        padding-bottom: 15px;
    }

    .info-container, .skill-list {
        background-color: tan;
    }
    
    .service-container, .facts-container {
        background-color: lightgray;
    }

    .about-me, .facts-heading, .project-heading, .skills-heading {
        font-size: 45px;
    }

    .info-text {
        font-size: 20px;
    }

    .skill-list {
        margin: auto;
        justify-content: center;
        display: grid;
        grid-template-columns: auto;
    }

    .skills-text {
        font-size: 20px;
    }

    .vacation-img {
        width: 80%;
        margin: auto;
        border-radius: 25%;
    }
    
    .image-container {
        display: grid;
        grid-template-columns: auto;
        margin: auto;
        justify-content: center;
        padding-bottom: 15%;
    }
}

// Tablet
@media screen and (min-width: 577px) and (max-width: 835px) {
    .container-home {
        // padding: 0 35%;
        // width: 85%;
    }

    .container-two {
        display: grid;
        grid-template-columns: auto;
    }

    .skill-list {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .info-container, .service-container {
        
    }
}

// Laptop
@media screen and (mix-width: 836px) and (max-width: 1280px) {
    .container-home {
        padding: 0 5%;
        width: 85%;
    }

    .skills-text {
        font-size: 20px;
    }
}